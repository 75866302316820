// MusicDetailsModal.js
import React from 'react';
import { Modal } from 'react-bootstrap';

const formatDuration = (seconds) => {
  if (!seconds) return '00:00:00'; // Return default value if seconds is undefined or falsy
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
};

const MusicDetailsModal = ({ isOpen, onClose, musicDetails }) => {
  const {
    type = 'unknown',
    season = null,
    episode = null,
    created_at = null,
    active = null,
    release_date = null,
    duration = 0,
    ...details
  } = musicDetails || {}; // Ensure musicDetails is defined

  // Determine active status label
  const activeStatus = (status) => {
    switch (status) {
      case 0:
        return 'Unpublished';
      case -1:
        return 'Archived';
      case 1:
        return 'Live';
      default:
        return 'Unknown';
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h2>{details.title || 'Untitled'}</h2>
      <img
        src={details.image || 'default_image_url'} // Fallback image if no image is provided
        alt={details.title || 'Image not available'}
        className="music-image"
      />
      {type === 'podcasts' && (
        <>
          <p><strong>Season:</strong> {season !== null ? season : 'N/A'}</p>
          <p><strong>Episode:</strong> {episode !== null ? episode : 'N/A'}</p>
        </>
      )}
      <p><strong>Artists:</strong> {details.artists_names?.join(', ') || 'Unknown artist'}</p>
      <p><strong>Writers:</strong> {details.writers?.join(', ') || 'Unknown writers'}</p>
      <p><strong>Released At:</strong> {release_date ? new Date(release_date).toLocaleString() : 'N/A'}</p>
      <p><strong>Created At:</strong> {created_at ? new Date(created_at).toLocaleString() : 'N/A'}</p>
      <p><strong>Active Status:</strong> {activeStatus(active)}</p>
      <p><strong>Duration:</strong> {formatDuration(duration)}</p>
      <p><strong>Likes:</strong> {details.likes !== undefined ? details.likes : 0}</p>
      <p><strong>Review:</strong> {details.review_message || 'No review available'}</p>
    </Modal>
  );
};

export default MusicDetailsModal;
