import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadIcon from "@mui/icons-material/Upload";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import musicImg from "../../Assets/musicnote.svg"; // Change to episode-specific image if you have one
import "./CreatedEpisodeItem.scss";
import { getS3BucketUrl } from "../../helper/constants";
import MusicDetailsModal from "../../components/MusicDetails"; // Import modal if needed

const ITEM_HEIGHT = 48;

const CreatedEpisodeItem = ({
  episode,
  handleClick,
  anchorEl,
  open,
  handleClose,
  handlePublish,
  handleUnpublish,
  handleDelete,
  handleArchive,
  handleEdit,
  isPublished,
  onPlayPause,
  currentlyPlaying,
}) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal
  
  const handlePlayPause = () => {
    if (currentlyPlaying) {
      setIsPlaying(!isPlaying);
    } else {
      setIsPlaying(true);
    }
    onPlayPause(audioRef);
  };

  // Format the duration from seconds to MM:SS format
  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div className="episodeItem">
      {/* Left Section */}
      <div className="episodeItemLeft">
        <img
          src={episode.image ? (episode.image_url)??getS3BucketUrl(episode.image) : musicImg}
          className="episodeImage"
          alt={episode.title || "No title"}
          style={{ cursor: episode.audio_file ? "pointer" : "default" }}
        />
        <div className="episodeInfo">
          <p className="episodeTitle">{episode.title || "Test"}</p>
          <Typography variant="body2" color="textSecondary">
            {new Date(episode.release_date).getTime() > Date.now()
              ? `Scheduled to ${new Date(
                  episode.release_date
                ).toLocaleDateString()}`
              : `Released on ${new Date(
                  episode.release_date
                ).toLocaleDateString()}`}
          </Typography>
          
          {/* Additional Info: Plays, Likes, Duration */}
          <div className="episodeStats">
            <div className="statItem">
              <PlayArrowIcon fontSize="small" /> {episode.play_count || 0} Plays
            </div>
            <div className="statItem">
              <FavoriteIcon fontSize="small" /> {episode.likes || 0} Likes
            </div>
            <div className="statItem">
              Duration: {formatDuration(episode.duration || 0)}
            </div>
            <div className="statItem">
              Streamed: {formatDuration((episode.streamed_m_sec || 0)/1000)}
            </div>
           
          </div>
        </div>
      </div>

      {/* Right Section */}
      <div className="episodeItemRight">
        <audio ref={audioRef} src={(episode.audio_file_url)??getS3BucketUrl(episode.audio_file)}></audio>
        <IconButton
          color="primary"
          onClick={handlePlayPause}
          disabled={!episode.audio_file}
        >
          {currentlyPlaying && isPlaying ? (
            <PauseCircleFilledIcon style={{ fontSize: 40 }} />
          ) : (
            <PlayCircleFilledIcon style={{ fontSize: 40 }} />
          )}
        </IconButton>

        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={(event) => handleClick(event, episode)}
        >
          <MoreVertIcon />
        </IconButton>

        {/* Options Menu */}
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
              borderRadius: "8px",
              border: "1px solid #ddd",
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          {episode.active === 1 && (
            <MenuItem onClick={handleUnpublish} disableRipple>
              <UploadIcon className="icons" /> UnPublish
            </MenuItem>
          )}

          {episode.active === 0 && (
            <>
              <MenuItem onClick={handlePublish} disableRipple>
                <UploadIcon className="icons" /> Publish
              </MenuItem>
              <Divider sx={{ my: 0.3 }} />
              <MenuItem onClick={handleArchive} disableRipple>
                <DeleteIcon className="icons" /> Archive
              </MenuItem>
            </>
          )}

          {episode.active === -1 && (
            <>
              <MenuItem onClick={handleUnpublish} disableRipple>
                <UploadIcon className="icons" /> Unpublish
              </MenuItem>
              <Divider sx={{ my: 0.3 }} />
              <MenuItem onClick={handleDelete} disableRipple>
                <DeleteIcon className="icons" /> Delete
              </MenuItem>
            </>
          )}
        </Menu>
      </div>
    </div>
  );
};

CreatedEpisodeItem.propTypes = {
  episode: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  anchorEl: PropTypes.any,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handlePublish: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  isPublished: PropTypes.bool.isRequired,
  onPlayPause: PropTypes.func.isRequired,
  currentlyPlaying: PropTypes.bool,
};

export default CreatedEpisodeItem;
