import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import {
  fetchCreatedEpisodes,
  reviewMusic,
  unpublishMusic,
  deleteMusic,
  archiveMusic,
} from "../../helper/musicHelper"; // Create a helper function to fetch episodes
import "./CreatedEpisode.scss";
import { Toaster, toast } from "react-hot-toast";
import episodeImage from "../../Assets/microphone-2.svg";
import { useNavigate } from "react-router-dom";
import CreatedEpisodeItem from "./CreatedEpisodeItem";
import Modal from "react-modal";
import checked from "../../Assets/checked.png";

Modal.setAppElement("#root");

const CreatedEpisode = () => {
  const [episodeList, setEpisodeList] = useState([]);
  const [unpublishedEpisodeList, setUnpublishedEpisodeList] = useState([]);
  const [archivedEpisodeList, setArchivedEpisodeList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("published");
  const [selectedEpisode, setSelectedEpisode] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [totalPages, setTotalPages] = useState(1); // Total pages returned from the server
  const [itemsPerPage, setItemsPerPage] = useState(10); // Items per page (can be changed as needed)

  const open = Boolean(anchorEl);

  const openConfirmation = (episode) => {
    handleClose();
    setConfirmationIsOpen(true);
  };

  const closeConfirmation = () => {
    setSelectedEpisode(null);
    setConfirmationIsOpen(false);
  };

  const handleClick = (event, episode) => {
    setAnchorEl(event.currentTarget);
    setSelectedEpisode(episode);
  };
  const handleClose = () => {
    setAnchorEl(null);
    // setSelectedEpisode(null);
  };

  const handleEdit = () => {
    const serializableEpisode = {
      id: selectedEpisode.id,
      title: selectedEpisode.title || "Unknown",
      writers: selectedEpisode.writers,
      artists_names: selectedEpisode.artists_names,
      genre: selectedEpisode.category_id,
      language: selectedEpisode.language,
      image: selectedEpisode.image,
      audio_file: selectedEpisode.audio_file,
      // Add other necessary properties here
    };

    // console.log(serializableMusic);
    navigate("/publish-episode", {
      state: { selectedEpisode: serializableEpisode },
    });
  };

  const handlePublish = async () => {
    if (!selectedEpisode) return;

    try {
      await reviewMusic(selectedEpisode.id); // Simulate API call to publish music

      // Update the selected music's active status to 1
      const updatedEpisode = { ...selectedEpisode, active: 1 };

      setUnpublishedEpisodeList((prev) =>
        prev.filter((episode) => episode.id !== selectedEpisode.id)
      );

      setEpisodeList((prev) => [...prev, updatedEpisode]);

      toast.success("Episode Published Successfully");
    } catch (error) {
      toast.error(error.error);
    } finally {
      handleClose();
    }
  };

  const handleUnpublish = async () => {
    if (!selectedEpisode) return;

    try {
      await unpublishMusic(selectedEpisode.id); // Simulate API call to publish music

      // Update the selected episode's active status to -1 (archived)
      const updatedEpisode = { ...selectedEpisode, active: 0 };

      if (activeTab === "published") {
        setEpisodeList((prev) =>
          prev.filter((music) => music.id !== selectedEpisode.id)
        );
      } else if (activeTab === "archived") {
        setArchivedEpisodeList((prev) =>
          prev.filter((music) => music.id !== selectedEpisode.id)
        );
      }

      setUnpublishedEpisodeList((prev) => [...prev, updatedEpisode]);
      toast.success("Episode Unpublished Succesfully");
    } catch (error) {
      toast.error(error.error);
    } finally {
      handleClose();
    }
  };

  // console.log(selectedEpisode);
  const handleDelete = async () => {
    if (!selectedEpisode) return;

    try {
      // console.log(selectedEpisode.id);
      await deleteMusic(selectedEpisode.id); // API call to delete music

      // Remove the deleted music from the appropriate list
      if (selectedEpisode.active === 1) {
        setEpisodeList((prevList) =>
          prevList.filter((episode) => episode.id !== selectedEpisode.id)
        );
      } else if (selectedEpisode.active === 0) {
        setUnpublishedEpisodeList((prevList) =>
          prevList.filter((episode) => episode.id !== selectedEpisode.id)
        );
      } else {
        setArchivedEpisodeList((prevList) =>
          prevList.filter((episode) => episode.id !== selectedEpisode.id)
        );
      }

      toast.success("Episode deleted successfully");
    } catch (error) {
      // console.error('Error deleting episode:', error);
      toast.error(error.error);
    } finally {
      closeConfirmation();
    }
  };

  const handleArchive = async () => {
    if (!selectedEpisode) return;

    try {
      await archiveMusic(selectedEpisode.id);

      // Update the selected episode's active status to -1 (archived)
      const updatedEpisode = { ...selectedEpisode, active: -1 };

      if (selectedEpisode.active === 0) {
        setUnpublishedEpisodeList((prevList) =>
          prevList.filter((music) => music.id !== selectedEpisode.id)
        );
      }

      setArchivedEpisodeList((prev) => [...prev, updatedEpisode]);

      toast.success("Music archived successfully");
    } catch (error) {
      toast.error(error.error);
    } finally {
      closeConfirmation();
    }
  };

  useEffect(() => {
    const loadCreatedEpisodes = async () => {
      setLoading(true);
      try {
        const episodeData = await fetchCreatedEpisodes(
          currentPage,
          itemsPerPage
        ); // Pass pagination params
        setEpisodeList(
          episodeData.data.filter((episode) => episode.active === 1)
        );
        setUnpublishedEpisodeList(
          episodeData.data.filter((episode) => episode.active === 0)
        );
        setArchivedEpisodeList(
          episodeData.data.filter((episode) => episode.active === -1)
        );
        setTotalPages(episodeData.totalPages); // Assuming the response contains total pages
      } catch (error) {
        toast.error(error.error);
      } finally {
        setLoading(false);
      }
    };

    loadCreatedEpisodes();
  }, [currentPage, itemsPerPage]); // Re-fetch data when page or items per page changes

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const renderPagination = () => {
    const pages = [];
    const maxVisiblePages = 5; // Set the maximum number of visible page buttons
    const halfVisible = Math.floor(maxVisiblePages / 2); // Calculate half the max visible pages

    // Calculate start and end page numbers
    let startPage = Math.max(1, currentPage - halfVisible);
    let endPage = Math.min(totalPages, currentPage + halfVisible);

    // Adjust if near the start
    if (currentPage <= halfVisible) {
      endPage = Math.min(maxVisiblePages, totalPages);
    }

    // Adjust if near the end
    if (currentPage + halfVisible >= totalPages) {
      startPage = Math.max(1, totalPages - maxVisiblePages + 1);
    }

    // Add "Previous" button
    if (currentPage > 1) {
      pages.push(
        <button key="prev" onClick={() => handlePageChange(currentPage - 1)}>
          &laquo; Previous
        </button>
      );
    }

    // Add "1" page button
    if (startPage > 1) {
      pages.push(
        <button key={1} onClick={() => handlePageChange(1)}>
          1
        </button>
      );
      // Add ellipsis if there are pages skipped
      if (startPage > 2) {
        pages.push(<span key="ellipsis-start">...</span>);
      }
    }

    // Add page number buttons within the calculated range
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          className={currentPage === i ? "active" : ""}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }

    // Add the last page button
    if (endPage < totalPages) {
      // Add ellipsis if there are pages skipped
      if (endPage < totalPages - 1) {
        pages.push(<span key="ellipsis-end">...</span>);
      }
      pages.push(
        <button key={totalPages} onClick={() => handlePageChange(totalPages)}>
          {totalPages}
        </button>
      );
    }

    // Add "Next" button
    if (currentPage < totalPages) {
      pages.push(
        <button key="next" onClick={() => handlePageChange(currentPage + 1)}>
          Next &raquo;
        </button>
      );
    }

    return <div className="pagination">{pages}</div>;
  };

  useEffect(() => {
    // Stop currently playing music when switching tabs
    if (currentlyPlaying) {
      currentlyPlaying.audioRef.current?.pause();
      setCurrentlyPlaying(null);
    }
  }, [activeTab]);

  const handlePlayPause = (musicId, audioRef) => {
    if (
      currentlyPlaying &&
      currentlyPlaying.audioRef &&
      currentlyPlaying.id !== musicId
    ) {
      currentlyPlaying.audioRef.current?.pause();
      currentlyPlaying.audioRef.current.currentTime = 0; // Reset time to start
    }

    if (currentlyPlaying && currentlyPlaying.id === musicId) {
      if (currentlyPlaying.audioRef.current) {
        if (currentlyPlaying.audioRef.current.paused) {
          currentlyPlaying.audioRef.current.play();
        } else {
          currentlyPlaying.audioRef.current.pause();
        }
      }
    } else {
      audioRef.current?.play();
      setCurrentlyPlaying({ id: musicId, audioRef });

      // Listen for the 'ended' event to reset the currently playing state when the song ends
      audioRef.current.onended = () => {
        setCurrentlyPlaying(null);
      };
    }
  };

  const renderEpisodeList = () => {
    let list = [];
    switch (activeTab) {
      case "published":
        list = episodeList;
        break;
      case "unpublished":
        list = unpublishedEpisodeList;
        break;
      case "archived":
        list = archivedEpisodeList;
        break;
      default:
        list = episodeList;
        break;
    }

    if (loading) {
      return <div>Loading...</div>;
    }

    if (list.length > 0) {
      return (
        <div className="episodeList">
          {list.map((episode) => (
            <CreatedEpisodeItem
              key={episode.id}
              episode={episode}
              handleClick={handleClick}
              anchorEl={anchorEl}
              open={open}
              handleClose={handleClose}
              handlePublish={handlePublish}
              handleUnpublish={handleUnpublish}
              handleDelete={() => openConfirmation(episode)}
              handleArchive={() => openConfirmation(episode)}
              handleEdit={handleEdit}
              isPublished={activeTab === "published"}
              onPlayPause={(audioRef) => handlePlayPause(episode.id, audioRef)}
              currentlyPlaying={currentlyPlaying?.id === episode.id}
            />
          ))}
        </div>
      );
    } else {
      return (
        <div className="emptyState">
          <img src={episodeImage} alt="No music" />
          <p>Add episodes to see here</p>
        </div>
      );
    }
  };

  return (
    <div className="container">
      <Sidebar />
      <main className="content">
        <div className="app-container">
          <Toaster />
          <h1>My Assets (Episodes)</h1>
          <div className="tabs">
            <button
              className={activeTab === "published" ? "active" : ""}
              onClick={() => setActiveTab("published")}
            >
              Published
            </button>
            <button
              className={activeTab === "unpublished" ? "active" : ""}
              onClick={() => setActiveTab("unpublished")}
            >
              Unpublished
            </button>
            <button
              className={activeTab === "archived" ? "active" : ""}
              onClick={() => setActiveTab("archived")}
            >
              Archived
            </button>
          </div>
          {renderEpisodeList()}
          <footer className="pagination-footer">
            {!loading && renderPagination()}
          </footer>{" "}
        </div>

        {/* Confirmation modal code here */}
      </main>
    </div>
  );
};

export default CreatedEpisode;
